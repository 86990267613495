export const hoursOptions = [
  {
    text: "Definición, Misión y Visión",
    link: "/asosiacion",
    icon: "",
  },
  {
    text: "EFPA Europa",
    link: "/efpa-europa",
    icon: "",
  },
  {
    text: "Estructura organizativa",
    link: "/estructura-organizativa",
    icon: "",
  },
  {
    text: "Entidades colaboradoras (Socios corporativos)",
    link: "/entidades-colaboradoras",
    icon: "",
  },
  {
    text: "Busca tu asesor financiero certificado",
    link: "/",
    icon: "",
    options: [
      {
        text: "Registro EFPA España",
        link: "/busca-tu-asesor-financiero-certificado",
      },
      {
        text: "Registro EFPA Europa",
        link: "https://efpa-eu.org/",
      },
    ],
    isCollapse: true,
  },
]

export const responsiveMenu = [
  ...hoursOptions,
  {
    text: "Estatutos",
    link: "/docs/EFPA-ESTATUTOS.pdf",
    icon: "fas fa-file-invoice",
  },
  {
    text: "Dossier informativo",
    link:
      "/docs/dossier-informativo-efpa-asociacion.pdf",
    icon: "fas fa-file-invoice",
  },
]

export const dowloads = [
  {
    text: "Estatutos",
    link: "/docs/EFPA-ESTATUTOS.pdf",
    icon: "fas fa-file-invoice",
  },
  {
    text: "Dossier informativo",
    link:
      "/docs/dossier-informativo-efpa-asociacion.pdf",
    icon: "fas fa-file-invoice",
  },
]

export const paragraphs = `<p>EFPA España es la Asociación Española de Asesores y
 Planificadores Financieros, que cuenta con más de 35.000 asociados. 
 <a href="docs/EFPA-ESTATUTOS.pdf" target="_blank">Ver estatutos</a>.</p>
 <p>Su constitución tiene lugar en el año 2000 y su nacimiento en 
 España está totalmente ligado a la creación de la <em>European 
 Financial Planning Association</em> (EFPA). EFPA España actúa como plataforma 
 independiente de certificación profesional y como asociación que agrupa a los profesionales 
 dedicados al asesoramiento y la planificación financiera en nuestro país, debidamente certificados.</p>
 <p>La única asociación europea en nuestro país que representa y defiende los intereses de los profesionales 
 del asesoramiento y la planificación financiera personal.</p><p>EFPA España dispone de un sistema de gestión 
 de la calidad conforme con la Norma ISO 9001, cuyo alcance se especifica clicando en el siguiente sello AENOR:</p>
`

export const video = `
  <iframe src="https://player.vimeo.com/video/236566450?color=21coe3&title=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
`
export const list = `
  <ul>
    <li>Acreditar y certificar los conocimientos, habilidades y aptitudes de los profesionales dedicados al asesoramiento y planificación financiera-personal, asociados a EFPA.</li>
    <li>Asegurar el nivel de conocimientos y la formación continua de sus asociados a través de la exigencia de la recertificación.</li>
    <li>Asegurar una conducta ética profesional a través del acatamiento del <a href="docs/codigo_etico.pdf" target="_blank">Código Ético de la asociación</a> entre los miembros de EFPA España.</li>
    <li>Promover y dar a conocer las certificaciones profesionales: EIA <em> European Investment Assistant</em>, EIP <em>European Investment Practitioner</em>, EFPA <em>European Financial Advisor</em> y EFPA <em>European Financial Planner</em>, como las más reconocidas, respetadas  y las de más alta calidad en el sector del asesoramiento y planificación financiera personal, disponibles en Europa y en el resto del mundo.</li>
    <li>Convertir el registro de certificados y miembros de la asociación como el único legal y oficial reconocido por el sector.</li>
  </ul>
`
