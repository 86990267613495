import React from "react"
import Layout from "../components/Layout"
import TitleBlock from "../components/TitleBlock"
import Hero from "../components/Hero"
import Breadcrumb from "../components/Breadcrumb"
import { paragraphs, video, list } from "../data/asosiation-data"
import parse from "react-html-parser"
import DownloadBtn from "../components/DownloadBtn"
import TitlePage from "../components/TitlePage"

export default function Association({ location }) {
  const path = [
    { label: "Inicio", to: "/" },
    { label: "Quiénes somos - Definición, Misión y Visión" },
  ]
  return (
    <Layout>
      <TitlePage
        title="Asociacion EFPA, definición, Misión y Visión"
        url={location.pathname}
      />
      <Hero image="/imgs/quienes-somos.jpg" text="Quiénes somos" />
      <div className="container950">
        <Breadcrumb path={path} className="Assosiation-breadcrumb" />
        <div className="row efpa-row">
          <div className="col-12 Assosiation-col">
            <div className="Assosiation">
              <TitleBlock
                title="Definición, Misión y Visión"
                icon="fas fa-list"
              />
              <div className="Assosiation-content">
                <TitleBlock
                  title="Definición"
                  icon="fas fa-layer-group"
                  className="Assosiation-border"
                />
                {parse(paragraphs)}
                <a
                  href="/docs/CertificadoER-0500-2014_ES_2021-09-28-IQnetES.pdf"
                  target="_blank"
                >
                  <img src="/imgs/aenor.png" alt="aenor logo" />
                </a>
              </div>
              <div className="Assosiation-content">
                <TitleBlock
                  title="Misión y Visión"
                  icon="fas fa-layer-group"
                  className="Assosiation-border"
                />
                <div className="Assosiation-list">{parse(list)}</div>
              </div>
              <div className="Assosiation-btns">
                <DownloadBtn
                  text="Política de calidad"
                  link="/politica-de-calidad"
                  className="mr-2"
                />
                <DownloadBtn
                  text="Estatutos"
                  link="/docs/EFPA-ESTATUTOS.pdf"
                  className="mr-2"
                />
                <DownloadBtn
                  text="Código ético"
                  link="/docs/codigo_etico.pdf"
                />
              </div>
              <DownloadBtn
                text="Proyecto de calidad de la formación financiera EQAFIT seleccionado por el programa Erasmus+"
                link="https://efpa.es/actualidad/20/proyectos-europeos/2604/efpa-espana-entidad-participante-en-el-proyecto-de-calidad-de-la-formacion-financiera-eqafit-seleccionado-por-el-programa-erasmus/"
              />
              <div className="Assosiation-video">{parse(video)}</div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
